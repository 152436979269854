import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect, HashRouter } from "react-router-dom";

import AuthLayout from "layouts/Auth/Auth.js";
import AdminLayout from "layouts/Admin/Admin.js";
import Ingresar from "./views/usuario/Ingresar.js";

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.1.0";
import "assets/demo/demo.css";

const hist = createBrowserHistory();

ReactDOM.render(
    <HashRouter>
        <Switch>
            <Route path="/auth" render={props => <AuthLayout {...props} />} />
            <Route path="/admin" render={props => <AdminLayout {...props} />} />
            <Route path="/ingresar" component={Ingresar} />
            <Route path="/" component={Ingresar} />
        </Switch>
    </HashRouter>,
    document.getElementById("root")
);
