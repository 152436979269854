import React from "react";
import {Col, Row} from "reactstrap";

import Iframe from 'react-iframe';
import axios from 'axios';
import { BeatLoader } from "react-spinners";
import NotificationAlert from "react-notification-alert";

class TipoMedio extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            msg: "",
            urlInforme: "",
            open: false,
            loading: false,
            idOpcion: 9,
            url: process.env.REACT_APP_API_URL
        };
    }

    componentDidMount() {
        this.showSpinner();
        console.log(window.innerHeight);

        const options = {
            headers: { 'Content-Type': 'application/json' }
        };

        const data = {
            idu: localStorage.getItem('o6'),
            ido: this.state.idOpcion,
            idt: localStorage.getItem('o7')
        };

        axios.post( this.state.url + '/usuario/opcion.php', data, options)
            .then(response => {
                //console.log('response data ', response.data);
                //console.log('response msg ', response.data.msg);

                if (response.data.status === 200) {
                    this.hideSpinner();
                    this.setState({ urlInforme: response.data.data.opcion })
                }
                else {
                    this.hideSpinner();
                    this.notify("tc", 4, response.data.msg);
                }
            })
            .catch(err => {
                //console.log('err', err.response);
                this.hideSpinner();
                this.notify("tc", 4, err.response.data.msg);
            });
    }

    showSpinner() {
        document.getElementById("divSpinner").style.display = "flex";
        //console.log('showSpinner ', document.getElementById("divSpinner").style.display);
        this.setState({
            loading: true
        });
    }

    hideSpinner() {
        document.getElementById("divSpinner").style.display = "none";
        //console.log('hideSpinner ', document.getElementById("divSpinner").style.display);
        this.setState({
            loading: false
        });
    }

    notify = (place, color, msg) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {msg}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    render() {
        return (
            <>
                <div className="content">
                    <div id="divSpinner" style={{ display: "none", backgroundColor: "rgba(0, 0, 0, 0.4)", zIndex: "50000", position: "fixed", top: "0px", left: "0px", bottom: "0px", right: "0px", alignItems: "center", overflow: "auto" }}>
                        <div style={{ maxHeight: "100%", margin: "0px auto" }}>
                            <BeatLoader
                                size={50}
                                color={"#fff"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                    <NotificationAlert ref="notificationAlert" />
                    <Row>
                        <Col lg="12" md="12">

                            {this.state.urlInforme !== '' ?
                                <Iframe url={this.state.urlInforme}
                                        width="100%"
                                        height={window.innerHeight < 700 ? 475 : 750}
                                        id="ifrInforme"
                                        display="initial"
                                        position="relative"/>
                                :
                                null
                            }
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default TipoMedio;
