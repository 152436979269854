import Atl from "views/atl/Atl";
import Digital from "views/digital/Digital";

const routes = [
    {
        path: "/digital",
        collapse: false,
        name: "Digital",
        rtlName: "صفحات",
        icon: "fas fa-desktop",
        component: Digital,
        layout: "/admin"
    },
    {
        path: "/atl",
        collapse: false,
        name: "Atl",
        rtlName: "صفحات",
        icon: "fas fa-bullhorn",
        component: Atl,
        layout: "/admin"
    }
];

export default routes;
