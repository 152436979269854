/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

class FooterLogin extends React.Component {
    render() {
        return (
            <footer
                className={"footer" + (this.props.default ? " footer-default" : "")}
            >
                <Container fluid={this.props.fluid ? true : false}>
                    <ul className="nav">

                    </ul>
                    <div className="copyright text-dark">
                        © {new Date().getFullYear()} {" "}
                    </div>
                </Container>
            </footer>
        );
    }
}

FooterLogin.propTypes = {
    default: PropTypes.bool,
    fluid: PropTypes.bool
};

export default FooterLogin;
