
import React from "react";
import classnames from "classnames";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row
} from "reactstrap";
import routes from "../../routes";
import {Redirect, Switch} from "react-router-dom";
import FooterLogin from "../../components/Footer/FooterLogin";

import NotificationAlert from "react-notification-alert";
import { GoogleReCaptchaProvider, GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import axios from 'axios';
import { BeatLoader } from "react-spinners";

import ClearCache from "react-clear-cache";

class Ingresar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            msg: '',
            user: '',
            password: '',
            token: '',
            open: false,
            loading: false,
            url: process.env.REACT_APP_API_URL
        }
    }

    componentDidMount() {
        document.body.classList.toggle("login-page");
    }

    componentWillUnmount() {
        document.body.classList.toggle("login-page");
    }

    handleChange = (e) => {
        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
            [name]: value
        });
    };

    validarCredenciales = (e) => {
        e.preventDefault();
        this.showSpinner();

        window.grecaptcha.execute('6LctmnApAAAAAM7jc0AgQwU0HBGXxP9hSP4W8usk', {
            action: 'homepage'
        })
            .then(token => {
                //console.log('token ', token);
                this.setState({token: token});
                const recaptchaValue = token;

                if (recaptchaValue === '' || recaptchaValue === null) {
                    this.hideSpinner();
                    this.notify("tc", 4, 'No se ha podido verificar el recaptcha.');
                    return false;
                } else {
                    if (this.state.user !== '' && this.state.password !== '') {
                        const options = {
                            headers: {
                              'Content-Type': 'application/json, text/plain, */*',
                           }
                        };

                        const data = {
                            usuario: this.state.user,
                            clave: this.state.password,
                            captcha: recaptchaValue
                        };

                        axios.post( this.state.url + '/usuario/login.php', data, options)
                            .then(response => {
                                console.log('response data ', response);
                                //console.log('response msg ', response.data.msg);

                                if (response.data.status === 200) {
                                    this.hideSpinner();
                                    localStorage.setItem('o1', response.data.data.usuario);
                                    localStorage.setItem('o2', response.data.data.nombres + ' ' + response.data.data.apellidos);

                                    const nombres = response.data.data.nombres.indexOf(" ") > 0 ? response.data.data.nombres.substring(0, response.data.data.nombres.indexOf(" ")) : response.data.data.nombres;
                                    const apellidos = response.data.data.apellidos.indexOf(" ") > 0 ? response.data.data.apellidos.substring(0, response.data.data.apellidos.indexOf(" ")) : response.data.data.apellidos;

                                    //localStorage.setItem('nombrer', response.data.data.nombres.substring(0, response.data.data.nombres.indexOf(" ")) + ' ' + response.data.data.apellidos.substring(0, response.data.data.apellidos.indexOf(" ")));
                                    localStorage.setItem('o3', nombres + " " + apellidos);
                                    localStorage.setItem('o4', response.data.data.nombre_cliente);
                                    localStorage.setItem('o5', response.data.data.nombre_agencia);
                                    localStorage.setItem('o6', response.data.data.id);
                                    localStorage.setItem('o7', response.data.data.tk);
                                    this.props.history.push('/admin/digital');
                                }
                                else {
                                    this.hideSpinner();
                                    this.notify("tc", 4, response.data.msg);
                                }
                            })
                            .catch(err => {
                                console.log('err', err);
                                this.hideSpinner();
                                this.notify("tc", 4, err.response.data.msg);
                            });

                    } else {
                        this.hideSpinner();
                        this.notify("tc", 4, "El usuario y contraseña son requeridos.");
                    }
                }
            })
            .catch(err => {
                this.hideSpinner();
                //console.log('Error ', err);
                this.notify("tc", 4, err.response.data.msg);
            });
    }

    showSpinner() {
        document.getElementById("divSpinner").style.display = "flex";
        //console.log('showSpinner ', document.getElementById("divSpinner").style.display);
        this.setState({
            loading: true
        });
    }

    hideSpinner() {
        document.getElementById("divSpinner").style.display = "none";
        //console.log('hideSpinner ', document.getElementById("divSpinner").style.display);
        this.setState({
            loading: false
        });
    }

    notify = (place, color, msg) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {msg}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    render() {
        return (
            <>
                <div className="wrapper wrapper-full-page" ref="fullPages">
                    <div className={"full-page login-page bg-gradient-white"}>
                        <div className="content">
                            <div id="divSpinner" style={{ display: "none", backgroundColor: "rgba(0, 0, 0, 0.4)", zIndex: "50000", position: "fixed", top: "0px", left: "0px", bottom: "0px", right: "0px", alignItems: "center", overflow: "auto" }}>
                                <div style={{ maxHeight: "100%", margin: "0px auto" }}>
                                    <BeatLoader
                                        size={50}
                                        color={"#fff"}
                                        loading={this.state.loading}
                                    />
                                </div>
                            </div>
                            <NotificationAlert ref="notificationAlert" />
                            <Container>
                                <Col className="ml-auto mr-auto" lg="4" md="6">
                                    <Form className="form" onSubmit={this.validarCredenciales}>
                                        <Card className="card-login card-white">
                                            <CardHeader style={{ paddingBottom: "90px", paddingTop: "5px", textAlign: "center" }}>
                                                {/*<img
                                            alt="..."
                                            src={require("assets/img/card-danger.png")}
                                            style={{ maxWidth: "110%" }}
                                        />*/}
                                                <CardTitle tag="h4">
                                                    <img
                                                        alt="..."
                                                        src={require("assets/img/dentsux.png")}
                                                        style={{ maxWidth: "50%", marginLeft: "-75px", paddingTop: "25px" }}
                                                    />
                                                </CardTitle>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col lg="12" md="12">
                                                        <h4 style={{ color: "#263148", textTransform: "capitalize", textAlign: "center", fontSize: "3em", fontWeight: "600", marginLeft: "5px" }}>Audience Intelligence</h4>
                                                    </Col>
                                                </Row>
                                                <InputGroup
                                                    className={classnames({
                                                        "input-group-focus": this.state.emailFocus
                                                    })}
                                                >
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="fas fa-user-circle" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        name="user"
                                                        id="user"
                                                        placeholder="Usuario"
                                                        type="text"
                                                        autoFocus required
                                                        onChange={this.handleChange}
                                                        onFocus={e => this.setState({ emailFocus: true })}
                                                        onBlur={e => this.setState({ emailFocus: false })}
                                                    />
                                                </InputGroup>
                                                <InputGroup
                                                    className={classnames({
                                                        "input-group-focus": this.state.passFocus
                                                    })}
                                                >
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="tim-icons icon-lock-circle" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder="Contraseña"
                                                        type="password"
                                                        id="password" name="password" required onChange={this.handleChange}
                                                        onFocus={e => this.setState({ passFocus: true })}
                                                        onBlur={e => this.setState({ passFocus: false })}
                                                    />
                                                </InputGroup>
                                            </CardBody>
                                            <CardFooter>
                                                <GoogleReCaptchaProvider reCaptchaKey="6LctmnApAAAAAM7jc0AgQwU0HBGXxP9hSP4W8usk">
                                                </GoogleReCaptchaProvider>
                                                <Button
                                                    block
                                                    className="mb-3"
                                                    color="pinterest"
                                                    size="lg"
                                                    type="submit"
                                                >
                                                    Ingresar
                                                </Button>
                                                <div className="pull-left">

                                                </div>
                                                <div className="pull-right">

                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Form>
                                </Col>
                            </Container>
                        </div>
                        <FooterLogin fluid />
                    </div>
                </div>
            </>
        );
    }
}

export default Ingresar;
